import { Breadcrumb, Col, Row, Form, Card, Button, Table, Container, InputGroup, Modal, Tab, Nav } from '@themesberg/react-bootstrap';
import { faHome, faQuran, faTrash, faAngleLeft, faAngleRight, faEdit, faRadiation } from "@fortawesome/free-solid-svg-icons";
import { baseurl, ProjectStatus, banknames, toi, tds, gst, companies } from "../../api";
import { getbill, disableBill, fetchbillsafterdisable } from '../../features/billslice'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify.cjs.development';
import React, { useState, useEffect } from "react";
import { triggerFunction, getPredefinedUrl } from '../../components/SignedUrl';
import { useSelector, useDispatch } from 'react-redux';
import { getcontacts, deleteContact } from '../../features/contactslice'
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { check } from '../../checkloggedin';
import Multiselect from "../../components/Multiselect";
import { getinvoice, disableinvoice } from '../../features/invoiceSlice'
import '../../style.css'; // Import the CSS file where your styles are defined
import { fetchProjects } from "../../features/projectslice";
import { getConsolidated } from "../../features/consolidatedSlice";
import { getexpenseinvoice } from "../../features/expenseInvoiceSlice";
import { getexpense } from '../../features/expenseSlice';

const ViewDebits = () => {
    const dispatch = useDispatch();
    const [typebill, setTypebill] = useState("expense")
    const [companyname, setCompanyName] = useState("")
    const [pnamearr, setPnamearr] = useState([])
    const [person, setPerson] = useState('')
    const [contacts, setContacts] = useState([])
    const [bank, setBank] = useState("")
    const [fgst, setgst] = useState('')
    const [ftds, settds] = useState('')
    const [isDisabled, setisDisabled] = useState(false)
    const [expenseInvoices, setExpenseInvoices] = useState([])
    const [expense, setExpense] = useState([])
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [id, setid] = useState('')
    const [editAmount, setEditAmount] = useState('')
    const [editBank, setEditBank] = useState('')
    const [editCompany, setEditCompany] = useState('')
    const [editDate, setEditDate] = useState('')
    const [editDescription, setEditDescription] = useState('')
    const [editInvoice, setEditInvoice] = useState('')
    const [editPerson, setEditPerson] = useState('')
    const [editProject, setEditProject] = useState('')
    const [editSubject, setEditSubject] = useState('')
    const [edittds, setEdittds] = useState('')
    const [editgst, setEditgst] = useState('')
    const [editStatus, setEditStatus] = useState('')
    const [editcreatedAt, seteditcreatedAt] = useState('')



    let [pname, setPname] = useState('')
    let [isActive, setIsActive] = useState(null)
    const handleprojectFetch = async () => {


        dispatch(fetchProjects({
            company: companyname ? companyname : null,
            status: isActive ? isActive : null
        })).then((resp) => {
            setPnamearr(resp)
        }).catch(error => {

        })
    }
    useEffect(() => {
        handleprojectFetch();
        dispatch(getcontacts()).then((res) => {
            setContacts(res)
        })
        dispatch(getexpenseinvoice()).then((res) => {
            setExpenseInvoices(res)
            handleFilter()
        })
        dispatch(getexpense()).then((res) => {
            setExpense(res)
            setData(res)
        })


    }, []);

    const handleFilter = () => {
        console.log(typebill)
        if (typebill == 'invoice') {
            setData(expenseInvoices)
            // console.log("expenseInvoices ==> ", expenseInvoices);
        }
        else {
            setData(expense)
        }
    }
    const handleEditModal = (item) => {
        setShowModal(true)
        if (typebill == 'expense') {
            setid(item._id)
            seteditcreatedAt(item.createdAt);
            setEditAmount(item.amount);
            setEditBank(item.bank);
            setEditPerson(item.person);
            setEditProject(item.project);
            setEditSubject(item.subject);
            setEditDescription(item.description);
            setEditInvoice(item.invoice);
            setEdittds(item.tds)
            setEditgst(item.gst)
        }
        if (typebill == 'invoice') {
            // seteid(item._id)
            // seteditcreatedAt(item.createdAt);
            // setEditAmount(item.amount);
            // setEditCompany(item.company);
            // setEditPerson(item.person);
            // setEditProject(item.project);
            // setEditSubject(item.subject);
            // setEditDescription(item.description);
        }
    }


    return (
        <>
            {/* Filter */}
            <form onSubmit={(e) => {
                handleFilter()
                e.preventDefault()
            }
            }>
                <Row>
                    <Col xs={12} md={3} >
                        <Form.Group id="pname" className="mb-4">
                            <Form.Label>Type</Form.Label>
                            <InputGroup>
                                <InputGroup.Text></InputGroup.Text>
                                <Form.Select value={typebill} onChange={(e) => {
                                    setTypebill(e.target.value);
                                    // changeData(e.target.value);

                                }}>
                                    <option value="">Select Option</option>
                                    <option value="invoice">Invoice</option>
                                    <option value="expense">Expense</option>
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Group id="pname" className="mb-4">
                            <Form.Label>Company Name</Form.Label>
                            <InputGroup>
                                <InputGroup.Text></InputGroup.Text>
                                <Form.Select value={companyname} onChange={(e) => {
                                    companyname = e.target.value
                                    setCompanyName(e.target.value)
                                    handleprojectFetch()
                                }}>
                                    <option value="">Select Option</option>
                                    {companies.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Group id="taskstatus" className="mb-4">
                            <Form.Label>Project Status</Form.Label>
                            <InputGroup>
                                <InputGroup.Text></InputGroup.Text>
                                <Form.Select value={isActive} onChange={(e) => {
                                    setIsActive(e.target.value);
                                    handleprojectFetch();
                                }}>
                                    <option value="">Select Option</option>
                                    {/* Mapping through the arr array to generate options */}
                                    {ProjectStatus.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Group id="pname" className="mb-4">
                            <Form.Label>Project name</Form.Label>
                            <InputGroup>
                                <InputGroup.Text></InputGroup.Text>
                                <Form.Select value={pname} onChange={(e) => {
                                    setPname(e.target.value)
                                }}>
                                    <option value="">Select Option</option>
                                    {pnamearr.map((option, index) => (
                                        <option key={index} value={option._id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Group id="people" className="mb-4">
                            <Form.Label>People</Form.Label>
                            <InputGroup>
                                <InputGroup.Text></InputGroup.Text>
                                <Form.Select value={person} onChange={(e) => setPerson(e.target.value)}>
                                    <option value="">Select Option</option>
                                    {contacts.map((option, index) => (
                                        <option key={index} value={option._id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={3}>
                        <Form.Group id="people" className="mb-4">
                            <Form.Label>Bank</Form.Label>
                            <InputGroup>
                                <InputGroup.Text></InputGroup.Text>
                                <Form.Select value={bank} onChange={(e) => setBank(e.target.value)}>
                                    <option value="">Select Option</option>
                                    {banknames.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    {/* tds */}
                    <Col xs={12} md={3}>
                        <Form.Group id="people" className="mb-4">
                            <Form.Label>TDS</Form.Label>
                            <InputGroup>
                                <InputGroup.Text></InputGroup.Text>
                                <Form.Select value={ftds} onChange={(e) => {
                                    settds(e.target.value)
                                }}>
                                    <option value="">Select Option</option>
                                    {tds.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    {/* gst */}
                    <Col xs={12} md={3}>
                        <Form.Group id="people" className="mb-4">
                            <Form.Label>GST</Form.Label>
                            <InputGroup>
                                <InputGroup.Text></InputGroup.Text>
                                <Form.Select value={fgst} onChange={(e) => {
                                    setgst(e.target.value)
                                }}>
                                    <option value="">Select Option</option>
                                    {gst.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    {/* isDisabled */}
                    <Col xs={12} md={3}>
                        {check()[1] == 'john_doe' ? (
                            <Form.Group id="people" className="mb-4">
                                <Form.Label>Is Disabled</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text></InputGroup.Text>
                                    <Form.Select value={isDisabled} onChange={(e) => {
                                        setisDisabled(e.target.value)
                                    }}>
                                        {/* <option value="">Select Option</option> */}
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>

                                    </Form.Select>
                                </InputGroup>
                            </Form.Group>) : (null)}
                    </Col>
                    <Col xs={12} md={3} className="d-flex justify-content-center">
                        <Button style={{ height: "70px" }} variant="primary" type="submit" className="w-100 mt-3">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
            {/* Table */}

            <Card style={{ width: "max-content" }} border="light" className="shadow-sm">
                <Card.Header>
                    <Row style={{ width: "max-content" }} className="align-items-center">
                        <Col>
                            <h5>Service List</h5>
                        </Col>
                        <Col style={{ width: "100%" }} className="text-end">
                            <Button variant="secondary" size="sm">See all</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Table responsive className="align-items-center table-flush">
                    {typebill == "invoice" ? (
                        <><thead className="thead-light">
                            <tr>
                                <th scope="col" className="unselectable" style={{ cursor: "pointer" }}>Created At</th>
                                <th scope="col">InvoiceId</th>
                                <th scope="col">Type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Amount Paid</th>
                                <th scope="col">Person</th>
                                <th scope="col">Company</th>
                                <th scope="col">Project</th>
                                <th scope="col">Subject</th>
                                <th scope="col">Description</th>
                                <th scope="col">Link to Files</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                            <tbody>
                                {data && data.map((row, index) => (
                                    <tr key={index}>
                                        <td style={{ border: "1px solid black" }}>{row.createdAt}</td>
                                        <td style={{ border: "1px solid black" }}>{row._id}</td>
                                        <td style={{ border: "1px solid black" }}>{row.type}</td>
                                        <td style={{ border: "1px solid black" }}>{row.amount}</td>
                                        <td style={{ border: "1px solid black" }}>{row.amount_paid}</td>
                                        <td style={{ border: "1px solid black" }}>{row.person}</td>
                                        <td style={{ border: "1px solid black" }}>{row.company}</td>
                                        <td style={{ border: "1px solid black" }}>{row.project}</td>
                                        <td style={{ border: "1px solid black" }}>{row.subject}</td>
                                        <td style={{ border: "1px solid black" }}>{row.description}</td>
                                        <td style={{ border: "1px solid black" }}>{row.link}</td>
                                        <td style={{ border: "1px solid black" }}>
                                            <Button variant="primary" size="sm"
                                                onClick={() => handleEditModal(row)}
                                            ><FontAwesomeIcon icon={faEdit} /></Button>
                                            <Button variant="primary" size="sm"><FontAwesomeIcon icon={faTrash} /></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </>
                    ) : (
                        <><thead className="thead-light">
                            <tr>
                                <th scope="col" className="unselectable" style={{ cursor: "pointer" }}>Created At</th>
                                <th scope="col">InvoiceId</th>
                                <th scope="col">Type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Amount Paid</th>
                                <th scope="col">Person</th>
                                <th scope="col">Company</th>
                                <th scope="col">Project</th>
                                <th scope="col">Subject</th>
                                <th scope="col">Description</th>
                                <th scope="col">Link to Files</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                            <tbody>
                                {data && data.map((row, index) => (
                                    <tr key={index}>
                                        <td style={{ border: "1px solid black" }}>{row.createdAt}</td>
                                        <td style={{ border: "1px solid black" }}>{row._id}</td>
                                        <td style={{ border: "1px solid black" }}>{row.type}</td>
                                        <td style={{ border: "1px solid black" }}>{row.amount}</td>
                                        <td style={{ border: "1px solid black" }}>{row.amount_paid}</td>
                                        <td style={{ border: "1px solid black" }}>{row.person}</td>
                                        <td style={{ border: "1px solid black" }}>{row.company}</td>
                                        <td style={{ border: "1px solid black" }}>{row.project}</td>
                                        <td style={{ border: "1px solid black" }}>{row.subject}</td>
                                        <td style={{ border: "1px solid black" }}>{row.description}</td>
                                        <td style={{ border: "1px solid black" }}>{row.link}</td>
                                        <td style={{ border: "1px solid black" }}>
                                            <Button variant="primary" size="sm"
                                                onClick={() => handleEditModal(row)}
                                            ><FontAwesomeIcon icon={faEdit} /></Button>
                                            <Button variant="primary" size="sm"><FontAwesomeIcon icon={faTrash} /></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </>
                    )}
                </Table>
            </Card>
            {/* Modal */}
            <Modal show={showModal} onHide={() => {
                setShowModal(false)
            }}>
                <Modal.Header>
                    <Modal.Title>Edit Bills</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group id="pname" className="mb-4">
                        <Form.Label>Creation Date</Form.Label>
                        <InputGroup>
                            <InputGroup.Text></InputGroup.Text>
                            <Form.Control autoFocus required type="date" placeholder="Amount" value={editcreatedAt} onChange={(e) => seteditcreatedAt(e.target.value)} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="editDescription">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control type="text" value={editAmount} onChange={(e) => setEditAmount(e.target.value)} />
                    </Form.Group>
                    {typebill == "expense" ? (<Form.Group className="mb-3" controlId="editDescription">
                        <Form.Label>Bank</Form.Label>
                        <Form.Select value={editBank} onChange={(e) => setEditBank(e.target.value)}>
                            <option value="">Select Option</option>
                            {banknames.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>) : (null)}
                    <Form.Group id="pname" className="mb-4">
                        <Form.Label>Contact</Form.Label>
                        <InputGroup>

                            <Form.Select required value={editPerson} onChange={(e) => setEditPerson(e.target.value)}>
                                <option value="">Select Option</option>
                                {/* Mapping through the arr array to generate options */}
                                {contacts.map((option, index) => (
                                    <option key={index} value={option._id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="editHeading">
                        <Form.Label>Project</Form.Label>
                        <Form.Select value={editProject} onChange={(e) => setEditProject(e.target.value)}>
                            <option value="">Select Option</option>
                            {/* Mapping through the arr array to generate options */}
                            {pnamearr.map((option, index) => (
                                <option key={index} value={option._id}>{option.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="editHeading">
                        <Form.Label>TDS</Form.Label>
                        <Form.Select value={edittds} onChange={(e) => setEdittds(e.target.value)}>
                            <option value="">Select Option</option>
                            {/* Mapping through the arr array to generate options */}
                            {tds.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="editHeading">
                        <Form.Label>GST</Form.Label>
                        <Form.Select value={editgst} onChange={(e) => setEditgst(e.target.value)}>
                            <option value="">Select Option</option>
                            {/* Mapping through the arr array to generate options */}
                            {gst.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="editHeading">
                        <Form.Label>Subject</Form.Label>
                    </Form.Group>
                    <textarea rows="4" cols="50" type="text" value={editSubject} onChange={(e) => setEditSubject(e.target.value)} />
                    <Form.Group className="mb-3" controlId="editHeading">
                        <Form.Label>Description</Form.Label>
                    </Form.Group>
                    <textarea rows="4" cols="50" type="text" value={editDescription} onChange={(e) => setEditDescription(e.target.value)} />
                    {typebill == "expense" ? (<Form.Group className="mb-3" controlId="editHeading">
                        <Form.Label>Invoice</Form.Label>
                        <Form.Select value={editInvoice} onChange={(e) => setEditInvoice(e.target.value)}>
                            <option value="">Select Option</option>
                            {/* Mapping through the arr array to generate options */}
                            {expenseInvoices.map((option, index) => (
                                <option key={index} value={option._id}>{option._id}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>) : (null)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        // setShowModal(false)
                        // setSelectedFiles([])
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => {
                        // handleEditSubmit(e)
                        // setSelectedFiles([])
                    }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ViewDebits